<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <b-img
          v-if="mode === 'dark'"
          :src="appDarkLogoImage"
          width="150"
          :alt="`${appName} logo`"
        />
        <b-img
          v-else
          :src="appLogoImage"
          width="150"
          :alt="`${appName} logo`"
        />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Register"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Create Account
          </b-card-title>
          <b-card-text class="mb-2">
            Please register using the form below
          </b-card-text>

          <!-- form -->
          <validation-observer
            ref="registerForm"
            #default="{invalid, dirty}"
          >
            <b-form
              class="auth-register-form mt-2"
              @submit.prevent="register"
            >
              <b-row>
                <b-col md="6">
                  <!-- first name -->
                  <b-form-group
                    label="First Name"
                    label-for="first_name"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="First Name"
                      rules="required"
                      vid="first_name"
                    >
                      <b-form-input
                        id="first_name"
                        v-model="firstName"
                        name="first_name"
                        :state="errors.length > 0 ? false:null"
                        placeholder="John"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <!-- last name -->
                  <b-form-group
                    label="Last Name"
                    label-for="last_name"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Last Name"
                      rules="required"
                      vid="last_name"
                    >
                      <b-form-input
                        id="last_name"
                        v-model="lastName"
                        name="last_name"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Doe"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <!-- email -->
                  <b-form-group
                    label="Email"
                    label-for="email"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Email"
                      rules="required|email"
                      vid="email"
                    >
                      <b-form-input
                        id="email"
                        v-model="userEmail"
                        name="email"
                        :state="errors.length > 0 ? false:null"
                        placeholder="john@example.com"
                        autocomplete="username"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <!-- password -->
                  <b-form-group
                    label-for="password"
                    label="Password"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Password"
                      rules="required|confirmed:c_password"
                      vid="password"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid':null"
                      >
                        <b-form-input
                          id="password"
                          v-model="password"
                          class="form-control-merge"
                          :type="passwordFieldType"
                          :state="errors.length > 0 ? false:null"
                          name="password"
                          placeholder="············"
                          autocomplete="new-password"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            :icon="passwordToggleIcon"
                            class="cursor-pointer"
                            @click="togglePasswordVisibility"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <!-- confirm password -->
                  <b-form-group
                    label-for="c_password"
                    label="Confirm Password"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Confirm Password"
                      rules="required"
                      vid="c_password"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid':null"
                      >
                        <b-form-input
                          id="c_password"
                          v-model="confirmPassword"
                          class="form-control-merge"
                          :type="passwordFieldType"
                          :state="errors.length > 0 ? false:null"
                          name="c_password"
                          placeholder="············"
                          autocomplete="new-password"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            :icon="passwordToggleIcon"
                            class="cursor-pointer"
                            @click="togglePasswordVisibility"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <!-- checkboxes -->
                  <b-form-group>
                    <b-form-checkbox
                      id="register-privacy-policy"
                      v-model="termsAgree"
                      name="register-privacy-policy"
                    >
                      I agree to
                      <b-link
                        :to="{name:'privacy-policy'}"
                        target="_blank"
                      >
                        Privacy Policy
                      </b-link>
                      &amp;
                      <b-link
                        :to="{name:'terms'}"
                        target="_blank"
                      >
                        Terms
                      </b-link>
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <!-- action button -->
                  <b-button
                    variant="primary"
                    block
                    type="submit"
                    :disabled="invalid || !dirty || !termsAgree"
                  >
                    Sign Up
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <span>Already have an account?</span>
            <b-link :to="{ name: 'auth-login' }">
              <span>&nbsp;Sign in instead</span>
            </b-link>
          </p>

          <!-- divider -->
          <!-- <div class="divider my-2">
            <div class="divider-text">
              or
            </div>
          </div> -->

          <!-- <div class="auth-footer-btn d-flex justify-content-center">
            <b-button
              variant="facebook"
              href="javascript:void(0)"
            >
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button
              variant="twitter"
              href="javascript:void(0)"
            >
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button
              variant="google"
              href="javascript:void(0)"
            >
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button
              variant="github"
              href="javascript:void(0)"
            >
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div> -->
        </b-col>
      </b-col>
    <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { $themeConfig } from '@themeConfig'
import {
  BRow, BCol, BLink, BButton, BForm, BFormCheckbox, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BImg, BCardTitle, BCardText,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'
import store from '@/store/index'

export default {
  components: {
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  setup() {
    // App Name
    const { appName, appLogoImage, appDarkLogoImage } = $themeConfig.app

    return {
      // App Name
      appName,
      appLogoImage,
      appDarkLogoImage,
    }
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      termsAgree: null,
      firstName: '',
      lastName: '',
      userEmail: '',
      password: '',
      confirmPassword: '',
      sideImg: require('@/assets/images/pages/register-v2.svg'),
      // validation
      required,
      email,
      inviteTokenErrors: null,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    mode() {
      return this.$store.state.appConfig.layout.skin
    },
  },
  methods: {
    register() {
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          // reset form validation state
          // so we can track if form data is dirty
          this.$refs.registerForm.reset()
          const registerForm = {
            name: `${this.firstName} ${this.lastName}`,
            first_name: this.firstName,
            last_name: this.lastName,
            email: this.userEmail,
            password: this.password,
            password_again: this.confirmPassword,
          }
          useJwt.register(registerForm)
            .then(() => {
              // login user
              useJwt.login({
                email: this.userEmail,
                password: this.password,
              })
                .then(res => {
                  const loginData = res.data.data
                  this.loginUser(loginData)
                })
                .catch(err => {
                  console.log('ERROR', err.response.data)
                  this.showErrorMessage(err.response.data.error)
                })
            })
            .catch(error => {
              if (error.response.status === 400) {
                // highlights all applicable erroneous inputs mentioned by the server.
                this.$refs.registerForm.setErrors({ password: error.response.data.details || error.response.data.error })
                // set invite_token error if present
                this.inviteTokenErrors = error.response.data.error.invite_token
              }
              this.showErrorMessage(error.response.data.error)
            })
        }
      })
    },
    loginUser(authTokenData) {
      // login user
      const loginToken = authTokenData.token

      useJwt.setToken(loginToken)
      useJwt.setRefreshToken(loginToken) // TODO

      // get current user data
      this.$store.dispatch('users/fetchCurrentUser')
        .then(userData => {
          // set user access ability
          this.$ability.update(userData.ability)

          // if account isn't verified yet, send to account verification page
          const nextRoute = userData.email_verified ? '/' : { name: 'auth-verify-account' }

          // send user to either dashboard or account verification
          this.$router.replace(nextRoute).then(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Welcome ${userData.fullName}`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: 'You have registered successfully.',
              },
            })
          })
        })
        .catch(error => {
          console.log('error', error)
        })
    },
    removeInviteToken() {
      this.inviteTokenErrors = null
      this.$router.replace({ name: 'auth-register' })
    },
    showErrorMessage(errorMessage) {
      this.$toast({
        component: ToastificationContent,
        position: 'bottom-right',
        props: {
          title: 'Error',
          icon: 'AlertCircleIcon',
          variant: 'danger',
          text: errorMessage,
        },
      },
      {
        timeout: false,
      })
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
